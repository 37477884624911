<template>
    <svg :width="size + 'px'" :fill="color" class="icon-profile" viewBox="0 0 99.998 99.998">
        <g id="Group_7957" data-name="Group 7957" transform="translate(-331 -995)">
            <circle id="Ellipse_337" data-name="Ellipse 337" cx="49.999" cy="49.999" r="49.999" transform="translate(331 995)" fill="#ffdcdc"/>
            <g id="Group_7727" data-name="Group 7727" transform="translate(354.724 1030.253)" style="mix-blend-mode: normal;isolation: isolate">
            <rect id="Rectangle_1911" data-name="Rectangle 1911" width="30.143" height="14.627" rx="1.264" transform="translate(11.658 7.635)" fill="#e04b4b"/>
            <path id="Path_3174" data-name="Path 3174" d="M773.779,3736.385H729.284a4.48,4.48,0,0,0-4.48,4.479v5.989h0a4.479,4.479,0,1,1,0,8.958v5.99a4.481,4.481,0,0,0,4.48,4.481h44.5a4.48,4.48,0,0,0,4.476-4.484v-5.988h0a4.48,4.48,0,0,1-4.482-4.478v-.005a4.479,4.479,0,0,1,4.484-4.475v-5.994A4.478,4.478,0,0,0,773.779,3736.385Zm-4.948,22.562a1.929,1.929,0,0,1-1.927,1.926H736.162a1.929,1.929,0,0,1-1.926-1.926v-15.226a1.927,1.927,0,0,1,1.926-1.926H766.9a1.927,1.927,0,0,1,1.927,1.926Z" transform="translate(-724.804 -3736.385)" fill="#e04b4b"/>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'Profile',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
	.st0{fill:#E3F4FE;}
	.st1{fill:#f97b4d;}
</style>
