<template>
    <div style="width: max-content; min-width: 280px;">
        <div class="d-flex flex-column" >
            <div class="d-flex align-content-center" v-if="!mobile">
                <h5 class="title-16">{{isLogged ? $t("layout.account.manage_your_session") : $t("layout.account.log_in_account") }}</h5>
            </div>
            <div class="d-flex m-0 w-100" v-if="!mobile && !employeeActiveCode">
                <div v-if="isLogged" class="d-flex justify-content-between m-0 w-100" @click="$emit('openConfirmLogout')">
                    <button class="login cursor-pointer bg-white position-relative" style="margin-left: 10px;">
                        <icon-close-session class="me-2" :color="'var(--blue-600)'" :size="22" />
                        <span class="action size-15">{{ $t("layout.account.logout") }}</span>
                    </button>
                </div>
                <div v-else class="d-flex align-items-center justify-content-between m-0 w-100" style="height: 43px;">
                    <button class="login cursor-pointer bg-white position-relative" @click="$emit('openLogin')">
                        <icon-close-session class="me-2" :color="'var(--blue-600)'" :size="22"/>
                        <span class="action size-15">{{ $t("layout.account.log_in") }}</span>
                    </button>
                    <div class="login-right d-flex size-14 align-items-center" @click="$emit('openSignIn')" style="margin-left: 30px;">
                        <span class="weight-400 color-dark-gray-200 login-question" style="margin-right: 10px;">{{$t("layout.account.are_you_new")}}</span>
                        <span class="weight-400 hover-color-dark-main-red color-blue-600 login-signup hover-underline cursor-pointer">{{ $t("layout.account.register") }}</span>                
                    </div>
                </div>
            </div>
            <hr v-if="!mobile" class="submenu-divider" style="margin-top: calc(1rem - 6px)" />
            <template v-if="!mobile">
                <div class="d-flex">
                    <h5 class="title-16">
                        {{ $t("layout.account.my_account") }}
                    </h5>
                </div>
                <ul class="submenu-list ps-0">
                    <li v-for="({address, viewBox, icon, style, size, text}) in accountOptionsValidate" :key="address" class="list-item cursor-pointer d-flex" 
                        :class="show(text) ? 'd-flex':'d-none'" @click.prevent="goTo(`${address}`)">
                        <component :is="icon" :width="size" class="list-icon fill-dark-gray-200" :viewBox="viewBox" :style="style"/>
                        <span class="list-subtitle size-15 color-dark-gray-200 weight-400">{{$t(`layout.account.${text}`)}}</span>
                        <icon-arrow :width="10" class="arrow-animation" />
                    </li>
                </ul>
            </template>
        </div>
        <template  v-if="vidvie != 1">
            <hr class="submenu-divider mt-0"/>
            <div class="d-flex flex-column">
                <div class="d-flex ">
                    <h5 class="title-16">{{ $t("layout.account.help") }}</h5>                
                </div>
                <div v-for="({link, text, index}) in options" :key="index">
                    <a class="d-flex list-item cursor-pointer" :href="link"> 
                        <icon-customer-service :width="16" class="list-icon fill-dark-gray-200" />
                        <span class="list-subtitle size-15 color-dark-gray-200 weight-400">{{$t(`${text}`)}}</span>
                        <icon-arrow :width="10" class="arrow-animation" />
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex" 
import Balance from '../../../../../account/view/Balance.vue'
import IconInvoice from '../../../../../account/assets/svg/iconInvoice.vue';
import iconTicketVue from '../../../../../account/assets/svg/iconTicket.vue';
export default {
    name: "AccountSubMenu",
    components: {
        Balance,
        IconKey: () => import("@/modules/layout/assets/svg/iconKey"),
        IconCloseSession: () => import("../../../../../../common/svg/iconCloseSession.vue"),
        IconPeople: () => import("@/modules/layout/assets/svg/iconPeople"),
        IconArrow: () => import("@/common/svg/iconArrow"),
        IconLock: () => import("@/modules/layout/assets/svg/iconLock"),
        IconOrder: () => import("@/common/svg/iconOrder.vue"),
        IconDevices: () => import("@/modules/layout/assets/svg/IconDevices"),
        IconAddresses: () => import("@/modules/layout/assets/svg/iconAddresses"),
        IconCustomerService: () => import("@/modules/layout/assets/svg/IconCustomerService"),
        IconGiftCode: () => import("@/common/svg/iconGiftCode.vue"),
        IconSettings: () => import("@/modules/layout/assets/svg/iconSettings.vue"),
        IconFavourite: () => import("@/common/svg/iconFavourite.vue"),
        IconInvoice,
        iconTicketVue,
    },
    data() {
        return {
            options: [
                {
                    link: '',
                    text: 'layout.account.customer_service'
                }
            ],
            accountOptions: [
                {
                    address: '/account',
                    viewBox: '0 0 200 200',
                    icon: 'icon-people',
                    size: 16,
                    text: 'your_account',
                    employee: true
                }, 
                
                {
                    address: '/account/orders',
                    viewBox: '0 0 250 250',
                    icon: 'icon-order',
                    style: "margin-left: 2px",
                    size: 13,
                    text: 'my_orders',
                    employee: true
                },
                {
                    address: '/account/devices',
                    viewBox: '0 0 250 250',
                    icon: 'icon-devices',
                    size: 16,
                    text: 'my_device_sessions',
                    employee: false
                },
                {
                    address: '/account/addresses',
                    viewBox: '0 0 200 200',
                    icon: 'icon-addresses',
                    size: 16,
                    text: 'my_addresses',
                    employee: true
                },                
                {
                    address: '/account/wishlist',
                    viewBox: '0 0 250 250',
                    icon: 'icon-favourite',
                    size: 16,
                    text: 'wishlist',
                    style: "fill: #A5B2B7;",
                    employee: true
                },
                {
                    address: '/account/settings',
                    viewBox: '120 120 200 200',
                    icon: 'icon-settings',
                    size: 16,
                    text: 'settings',
                    style: "fill: #A5B2B7;",
                    employee: false
                },
                {
                    address: '/account/quotes',
                    viewBox: '0 0 14.007 18.676',
                    icon: 'icon-invoice',
                    size: 11,
                    text: 'quotes',
                    style: "fill: #A5B2B7;margin-left: 3px",
                    employee: true
                },
                {
                    address: '/account/balance',
                    viewBox: '0 0 14.007 18.676',
                    icon: 'icon-invoice',
                    size: 11,
                    text: 'my_balance',
                    style: "fill: #A5B2B7;margin-left: 3px",
                    employee: true
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            isLogged: "auth/isLogged",
            user: "auth/userData",
            mobile: 'common/mobile',
            employeeActiveCode: 'auth/employeeActiveCode',
            employee: 'auth/employee',
            configurations: "common/configurations",
            vidvie: "home/vidvie",
        }),
        //regarding styles
        langLogoutWidth() {
            return this.$i18n.locale === 'en' ? '100px': '144px';
        },
        langWidth() {
            return this.$i18n.locale === 'en' ? '80px': '138px';
        },
        langMargin() {
            return this.$i18n.locale === 'en' ? 'calc(28px + 20%)': 'calc(25px + 12%)';
        },
        gotCode() {
            if (this.isLogged) {
                return this.user.code == "1";
            }
            return true;
        },
        accountOptionsValidate(){
            if(this.employeeActiveCode){
                return this.accountOptions.filter(option=>option.employee)
            } else if(this.employee?.entity?.activated_employee){
                return this.accountOptions
            }else{
                return this.accountOptions
            }
        },
    },
    methods: {
        goTo(path){
            this.$router.push({path}).catch(() => {});
            this.$emit('closeDrawer');
        },       
        show(item){ //if the option is wishlist validate your viewport is below 992px, else, show it
            return item === 'wishlist' ? this.viewPort('xl', { max: true }): true;
        },
    },
    created(){
        const intercomLink = this.configurations.general.web_page.url_intercom
        this.options[0].link = intercomLink
    }
}
</script>

<style scoped>
    span.action {
        transition: color 0.20s ease-in-out;
    }

    button.login {
        border: 1px solid transparent;
        border-radius: 5px;

        padding: 5px 0px;
        padding-right: 7px;
        padding-left: 3px;
        
        margin-top: -1px;
        margin-bottom: -1px;
        margin-left: 10px;

        height: max-content;
    }

    button.login > svg {
        padding-bottom: 4px;
    }

    button.login:hover span.action {
        color: var(--blue-600)
    }

    button.login:hover > .icon-close-session::v-deep #Path_1965{
        transform: translate(-344px, -90.5px);
        -moz-transform: translate(-344px, -90.5px); /* Firefox */
    }
    button.login > .icon-close-session::v-deep > #Path_1965 {
        transition: transform .25s var(--alt-ease-in-out);
    }

    button.login::before, button.login::after {
        position: absolute;

        border: 1px solid transparent;
        border-radius: 5px;

        box-sizing: inherit;
        content: '';
        width: 0;
        height: 0;
    }

    /* when we stop un-hover */
    button.login::before {
        top: 0;
        right: 0;

        transition: height 0.15s ease-in-out 0.1s, width 0.15s ease-in-out 0.2s, border-color 0.3s ease-in-out 0.3s;
    }
    button.login::after {
        bottom: 0;
        left: 0;

        transition: height 0.15s ease-in-out 0.35s, width 0.15s ease-in-out 0.45s, border-color 0.6s ease-in-out 0.35s;
    }
    button.login:hover::before, button.login:hover::after {
        width: 100%;
        height: 100%;
    }

    /* when we hover */
    button.login:hover::before {
        border-top-color: var(--blue-600);
        border-left-color: var(--blue-600);
        
        transition: width 0.15s ease-in-out 0.35s, height 0.15s ease-in-out 0.45s, border-color 0.15s ease-in-out 0.2s;
    }
    button.login:hover::after {
        border-bottom-color: var(--blue-600);
        border-right-color: var(--blue-600);

        transition: width 0.15s ease-in-out 0.1s, height 0.15s ease-in-out 0.2s, border-color 0.15s ease-in-out;
    }
    @media (max-width: 340px) { /* not recommended */
        button.login { 
            margin-left: 0px;
        }    
    }
</style>
